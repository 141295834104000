import React, { useEffect, useState, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import {motion, useScroll} from 'framer-motion';
import Image1 from '../Images/about1.jpg';
import Image2 from '../Images/about2.jpg';
import Image3 from '../Images/about3.jpg';
import ProgressiveImage from 'react-progressive-graceful-image';
import ProductBlue from '../Images/product-blue.jpg';
import ProductPink from '../Images/product-pink.jpg';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Faq from '../Images/faq.jpg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; 
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Marquee from "react-fast-marquee";

import Shopee from '../Images/Shopee.png';
import Instagram from '../Images/Instagram.png';
import Tiktok2 from '../Images/tiktok2.png';
import Twitter from '../Images/X.png';

import Tokped from '../Images/Tokped.png';
import Lazada from '../Images/Lazada.png';
import Tiktok from '../Images/tiktok.png';
import Logo from '../Images/logo.png';

import { Parallax, useParallax } from 'react-scroll-parallax';


import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

import Product_Blue from '../Images/product_blue.png';
import Product_Pink from '../Images/product_pink.png';
import About_Image from '../Images/about-image.png';

import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";





function Section() {

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };



    

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    const [open3, setOpen3] = React.useState(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);

    const [init, setInit] = useState(false);
    useEffect(() => {
        initParticlesEngine(async (engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {
        console.log(container);
    };

    const options = useMemo(
        () => ({
            fullScreen: {
                enable: false,
            },
            fpsLimit: 120,
            interactivity: {
                events: {
                    onClick: {
                        enable: true,
                        mode: "push",
                    },
                    onHover: {
                        enable: true,
                        mode: "repulse",
                    },
                },
                modes: {
                    push: {
                        quantity: 4,
                    },
                    repulse: {
                        distance: 100,
                        duration: 0.4,
                    },
                },
            },
            particles: {
                color: {
                    value: "#3F3763",
                },
                links: {
                    color: "#ffffff",
                    distance: 150,
                    enable: false,
                    opacity: 0.5,
                    width: 1,
                },
                move: {
                    direction: "top",
                    enable: true,
                    outModes: {
                        default: "out",
                    },
                    random: false,
                    speed: 3,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                    },
                    value: 80,
                },
                opacity: {
                    value: 0.5,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    value: { min: 1, max: 5 },
                },
            },
            detectRetina: true,
        }),
        [],
    );

    
  return (
    <>
    
          <div id="about" className="about" >
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12} sm={6} >
                      <div className="about-body">
                      <motion.div className="title"
                          initial={{ opacity: 0, x: -200 }}
                          whileInView={{opacity: 1, x: 0}}
                          transition={{duration: 0.6}}
                      >
                        Deodorant <span className="dancing">Reimagined:</span><br/>
                        <div className="subtitle-bold">Nature’s Power, Gentle on You</div>
                        {/* <div className="subtitle-bold">Nature’s Power, Gentle on You with Natural Shikimic Acid & Kombucha.</div> */}
                          {/* Deodorant <span className="dancing">Reimagined</span> : Nature’s Power, Gentle on You with Natural Shikimic Acid & Kombucha. Promoting healthier skin & long-lasting effectiveness against odor-causing bacteria */}
                      </motion.div>
                      <motion.div className="subtitle"
                          initial={{ opacity: 0, x: -200 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.8 }}
                      >
                          {/* FRESCENT is a conscious beauty brand dedicated to providing high-quality, redefining underarm care with unparalleled efficacy and a commitment to clean, sustainable ingredients. We craft luxurious and elegant solutions that elevate your self-care routine, empowering you to embrace a healthy lifestyle with confidence. It is free from harsh chemical and suitable for all skin types. */}
                          FRESCENT is a conscious beauty brand dedicated to providing high-quality, redefining underarm care with unparalleled efficacy and a commitment to clean, sustainable ingredients.
                          </motion.div></div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <div className="thumbnail border-1" >
                          <Parallax speed={-5}>
                            <ProgressiveImage src={Image1} >
                                    {(src) => <motion.img  src={Image1} className="image1" alt="" />}
                            </ProgressiveImage>
                      </Parallax>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      <div className="thumbnail right">
                          <Parallax speed={-5}>
                          <ProgressiveImage src={Image2} >
                              {(src) => <motion.img  src={Image2} className="image2" alt="" />}
                          </ProgressiveImage>
                          </Parallax>
                      </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      <div className="thumbnail border-3">
                          <Parallax speed={-5}>
                            <ProgressiveImage src={Image3} >
                                {(src) => <motion.img  src={Image3} className="image3" alt="" />}
                            </ProgressiveImage>
                          </Parallax>
                      </div>
                  </Grid>
              </Grid>
        </div>

          <div id="product" className="product">
            <motion.div className="title"
                  initial={{ opacity: 0, y: 200 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6 }}>Our Latest Creation</motion.div>

              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12} sm={6}>
                      <motion.div className="card" initial={{ opacity: 0, x: -200 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.6 }}>
                            <div className="thumbnail">
                              <Parallax speed={-5}>
                                <Button className="button-padding" data-modal="modal-one" onClick={handleOpen}>
                                            <img src={ProductBlue} className="img" alt="" />
                                </Button>
                            </Parallax>
                          </div>
                            <div className="name">Cashmere Cloud</div> 
                             <div className="sub">Deodorant Spray - 60ml </div>
                          {/* <div className="sub">Rp. 110.000 </div> */}
                          <Modal
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description" >
                              <div className="modal-body blue">
                                  <Grid container>
                                      <Grid item sm={4}><div className="alignment"><img src={Product_Blue} className="img" alt="" /></div>
                                          
                                    </Grid>
                                      <Grid item sm={8}>
                                          <Button onClick={handleClose} className="close-button">X</Button>
                                          <div className="modal-alignment">
                                            <div className="name">Cashmere Cloud</div>
                                          {/* <div className="sub">Rp. 110.000 | 60 ml</div> */}
                                          <div className="sub-details">Details Scent:</div> 
                                              <div className="details">Delicately balanced fragrance that is both fresh & sophisticated. Imagine a soft gentle cloud of comfort, this fragrance whispers of innocence and care.<br /><br /> Top notes of fresh green floral, as the scent unfolds, a touch of delicate sweetness emerges from Rose & Ylang, while the base notes capture the essence of Sweet Musky Woody, reminiscent of the baby powder that is warm, creating a scent that feels reassuring and timeless.</div> 
                                          </div>
                                          
                                    </Grid>
                                  </Grid>
                              </div>
                          </Modal>
                    </motion.div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      <motion.div className="card" initial={{ opacity: 0, x: 200 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.6 }}>
                          <div className="thumbnail">
                              <Parallax speed={-5}>
                                  <Button className="button-padding" data-modal="modal-two" onClick={handleOpen2}>
                                      <img src={ProductPink} className="img" alt="" />
                                  </Button>
                              </Parallax>
                          </div>
                          <div className="name">Cassady Patchouli’s Dream</div>
                          <div className="sub">Deodorant Spray - 60ml </div>
                          {/* <div className="sub">Rp. 110.000 </div> */}
                          
                          <Modal
                              open={open2}
                              onClose={handleClose2}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description" >
                              <div className="modal-body pink">
                                  <Grid container>
                                      <Grid item sm={4}><div className="alignment"><img src={Product_Pink} className="img" alt="" /></div>
                                          
                                      </Grid>
                                      <Grid item sm={8}>
                                          <Button onClick={handleClose2} className="close-button">X</Button>
                                      <div className="modal-alignment">
                                              <div className="name">Cassady Patchouli’s Dream</div>
                                              {/* <div className="sub">Rp. 110.000</div> */}
                                              <div className="sub-details">Details Scent:</div>
                                              <div className="details">This fragrance is a captivating blend of freshness, floral beauty, and sensual warmth. It's perfect for someone who wants a scent that is both sophisticated and playful. The citrus top notes offer an initial burst of energy, while the floral heart provides a romantic and feminine touch.<br /><br /> The musky, patchouli, and sandalwood base adds depth and sensuality, making it a truly captivating fragrance.</div>
                                      </div>
                                      </Grid>
                                  </Grid>
                              </div>
                          </Modal>
                      </motion.div>
                  </Grid>
              </Grid>
              
        </div>



        <div id="questions" className="questions">
              <motion.div initial={{ opacity: 0, y: -200 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }} className="title">Frequently Asked Questions</motion.div>
        <Grid container>
            <Grid item sm={6}>
            <div className="display">
            <div>
                      <motion.div initial={{ opacity: 0, x: -100 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.6 }}>
                      <Accordion className="accordion-body" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                          <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1bh-content"
                              id="panel1bh-header">
                                  <div className="name">Is FRESCENT BPOM Certified?</div>  
                          </AccordionSummary>
                          <hr></hr>
                          <AccordionDetails>
                                  <div className="answer">Yes, all of our products are BPOM certified.</div>
                          </AccordionDetails>
                      </Accordion>
                    </motion.div>
                      <motion.div initial={{ opacity: 0, x: -100 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.6, delay: 0.2 }}>
                      <Accordion className="accordion-body" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                          <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2bh-content"
                              id="panel2bh-header">
                                  <div className="name">How to use your Frescent?</div>
                          </AccordionSummary>
                          <hr></hr>
                          <AccordionDetails>
                                  <div className="answer">Spray 3 pumps towards your armpit, and allow to dry completely before dressing</div>
                          </AccordionDetails>
                      </Accordion>
                    </motion.div>
                      <motion.div initial={{ opacity: 0, x: -100 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.6, delay: 0.6 }}>
                      <Accordion className="accordion-body" expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                          <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel4bh-content"
                              id="panel4bh-header">
                                  <div className="name">Where to buy Frescent deodorant?</div>
                          </AccordionSummary>
                          <hr></hr>
                          <AccordionDetails>
                                  <div className="answer">Get our natural deodorant through all indonesian major e-commerce websites:
                                  Shopee,Lazada,Tokopedia, Tiktok (frescentofficial)</div>
                          </AccordionDetails>
                      </Accordion>
                    </motion.div>
                      <motion.div initial={{ opacity: 0, x: -100 }}
                          whileInView={{ opacity: 1, x: 0 }}
                          transition={{ duration: 0.6, delay: 1.2 }}>
                      <Accordion className="accordion-body" expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                          <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel7bh-content"
                              id="panel7bh-header">
                                  <div className="name">Is Frescent Aluminum-free, alcohol-free and does not contain paraben?</div>
                          </AccordionSummary>
                          <hr></hr>
                          <AccordionDetails>
                                  <div className="answer">Our product does NOT contain aluminum, alcohol, nor paraben. </div>
                          </AccordionDetails>
                      </Accordion>
                    </motion.div>
                          </div>
                      </div>
            </Grid>
            <Grid item sm={6}>
            <div className="relative">
                <motion.div  className="alignment">
                              <Parallax speed={-5}>
                                  <ProgressiveImage src={Faq} >
                                      {(src) => <motion.img src={Faq} className="img" alt="" />}
                                  </ProgressiveImage>
                              </Parallax>
                </motion.div>
            </div>
            </Grid>
        </Grid>
        </div>


        <div id="shop" className="shop">
            <div className="card">
                  <div className="title">Discover Your Favorites:</div>
                  <hr></hr>
                  <div className="placement">
                  <Grid container>
                          <Grid className="flex" item xs={12} sm={3}><a href="https://shopee.co.id/" target="_blank"><img src={Shopee} className="logo" alt="" /></a></Grid>
                          <Grid className="flex" item xs={12} sm={3}><a href="https://www.tokopedia.com/" target="_blank"><img src={Tokped} className="logo" alt="" /></a></Grid>
                          <Grid className="flex" item xs={12} sm={3}><a href="https://www.lazada.co.id/" target="_blank"><img src={Lazada} className="logo" alt="" /></a></Grid>
                          <Grid className="flex" item xs={12} sm={3}><a href="https://www.tiktok.com/" target="_blank"><img src={Tiktok} className="logo" alt="" /></a></Grid>  
                  </Grid>
                  
                  </div>
            </div>

              <div className="card">
                  <div className="title">Get in Touch Now</div>
                  {/* <div className="sub">Get in Touch Now.</div> */}
                      <hr></hr>
                  <div className="placement">
                    <Grid container>
                          <Grid className="flex" item xs={6} sm={2}>
                              <div><a className="nav" href="https://www.instagram.com/frescent.idn" target="_blank"><img src={Instagram} className="logo small" alt="" /></a></div>
                              <div><a className="nav-username" href="https://www.instagram.com/frescent.idn" target="_blank">@frescent.idn </a></div>
                        </Grid>
                          <Grid className="flex" item xs={6} sm={2}>
                              <div><a className="nav" href="https://www.tiktok.com/@frescentidn" target="_blank"><img src={Tiktok2} className="logo small" alt="" /></a></div>
                              <div><a className="nav-username" href="https://www.tiktok.com/@frescentidn" target="_blank">@frescentidn</a></div>
                        </Grid>
                    </Grid>
                  </div>

                  
              </div>
              <Particles
                  id="tsparticles"
                  particlesLoaded={particlesLoaded}
                  options={options} />
              
        </div>

        <div id="contact" className="footer">
            <Grid container>
                <Grid item sm={9} className="block">
                    <img src={Logo} id="logo2" className="logo" alt="" />
                      <div className="footer-title">FRESH ALL DAY THE NATURAL WAY</div>
                </Grid>
                  <Grid item sm={3} className="block">
                      <Button data-modal="modal-three" onClick={handleOpen3} className="about">ABOUT FRESCENT</Button>
                      <div className="contact">CONTACT US AT</div>
                      <div className="details"><div><PhoneIcon /></div>
                          <div><a className="nav" href="https://wa.me/6281380335700" target="_blank">0813 8033 5700</a></div>
                      </div>
                      <div className="details"><div><EmailIcon /></div>
                          <div><a className="nav" href="mailto:hi@frescent.co.id" target="_blank">hi@frescent.co.id </a></div>
                      </div>
                      <Modal
                          open={open3}
                          onClose={handleClose3}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description" >
                          <div className="about-modal">
                              <Grid container>
                                  <Grid item sm={4}>
                                  <div className="alignment"><img src={About_Image} className="img" alt="" /></div>
                                  </Grid>
                                  <Grid item sm={8}>
                                      <Button onClick={handleClose3} className="close-button">X</Button>
                                      <div className="modal-alignment">
                                          <div className="name">About Frescent</div>
                                          <div className="details">
                                                Welcome to Frescent, where we believe that feeling fresh and confident should be a part of everyday life. Our journey began with a simple idea: to create a deodorant that not only works effectively but also uses natural ingredients. <br /> <br />
                                                Today, we are proud to offer a product that embodies innovation, quality and sustainability. 
                                          </div>
                                          <div className="name">Frescent's Family</div>
                                          <div className="details">
                                              We invite you to join the Frescent family and experience the difference our products can make. Stay fresh, stay confident, and embrace each day with Frescent. 
                                          </div>
                                      </div>

                                  </Grid>
                              </Grid>
                          </div>





                          </Modal>
                    
                </Grid>
            </Grid>


        </div>

        <hr></hr>
          <div className="copyright">Copyright Ⓒ Frescent Natural Group</div>

    </>
  )
}

export default Section